import React from 'react'
import {Share2} from 'react-feather'
import CopyLink from './copy-link'

export default (props) => {

  const {roomName} = props

  return (
    <div id='chat-header'>

    </div>
  )

}
