import React from 'react'
//import {encodeRoom} from '../lib/room-encoding'
import {Helmet} from "react-helmet";

export default class SetNickname extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      nickname: 'Cam',
    }

    this.onSetNickname('Cam')
  }

  onChange(evt) {

    this.setState({
      nickname: evt.target.value
    });

  }

  onSetNickname(nickname) {

    const {onSetNickname} = this.props

    nickname = nickname.replace(/\s\s+/g, ' ')
    nickname = nickname.trim()

    onSetNickname(nickname)

  }
  render() {

    const {roomName, created} = this.props
    const {nickname} = this.state

    return (
      <div id='set-nickname' className='container' style={{opacity:0}}>
        <h3>{created ? 'Creating ' : null}{roomName}</h3>
        <h5>Set a nickname:</h5>
        <form onSubmit={(e) => {e.preventDefault(); this.onSetNickname(nickname)}} id="rn2">
          <div>
            <input
              type='text'
              placeholder='e.g. tom'
              value='Camera' id="rn3" 
              onChange={evt => this.onChange(evt)}
              required
              minLength='3'
            />
          </div>
          <div>
            <button id="rn"
              type='submit'
              className='button-primary'
            >
              Join Video Call
            </button>
          </div>
        </form>
      </div>
    )

  }

}
