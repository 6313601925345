import React from 'react'

export default () => {

  return (
    <div className='hero container'>
      <h1>👋</h1>
      <h5>Call ended.</h5>
      <a
        href="/"
        className='button button-primary home-button'
      >
        Home
      </a>
    </div>
  )

}
