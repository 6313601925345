import React from "react";
import { Shield, Users, Lock } from "react-feather";

export default (props) => {
  return (
    <div id="footer">
      <div className="container">
        <div className="row">
        </div>
        <div className="row">

        </div>
        <div className="row">
        </div>
        <div className="row">
        </div>
      </div>
    </div>
  );
};
